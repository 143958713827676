export interface Region {
  title: string
  description: string
  longDescription: string
  keywords: string
  slug: string
  slug2?: string
  seoDescription: string
}

export const regions: Region[] = [
  {
    title: "Praha",
    description: "Online kurzy programování pro děti a mládež v Praze. Učte se z domova, vhodné pro všechny.",
    longDescription:
      "V Praze nabízíme širokou škálu online kurzů programování pro děti a mládež. Tyto kurzy jsou přístupné odkudkoli, ideální pro každého bez ohledu na fyzické omezení. Poskytujeme flexibilní vzdělávací prostředí, které umožňuje účastníkům učit se vlastním tempem a rozvíjet kreativní a technické dovednosti z pohodlí domova. Naše kurzy jsou navrženy tak, aby podporovaly tvořivost, logické myšlení a problematiku řešení, přičemž jsou dostupné pro všechny věkové kategorie. Je to skvělá příležitost pro děti a mládež z Prahy a okolí objevovat svět technologií a software, a to i pro ty, kteří se potýkají s fyzickými omezeními nebo jsou na vozíku.",
    keywords: "online kurzy Praha, programování pro děti Praha, vzdělávání mládeže online",
    slug: "online-kurzy-programovani-deti-mladez-praha",
    slug2: "praha",
    seoDescription:
      "Online kurzy programování pro děti a mládež ze Středočeského kraje. Podporujeme učení odkudkoli s důrazem na rozvoj digitálních dovedností a kreativity."
  },
  {
    title: "Středočeský kraj",
    description: "Objevte online programování pro děti a mládež ze Středočeského kraje. Přístupné vzdělávání pro všechny.",
    longDescription:
      "Středočeský kraj přináší šanci dětem a mládeži zapojit se do online kurzů programování. Kurzy jsou navržené tak, aby podporovaly učení odkudkoli, a jsou otevřené pro účastníky s různými schopnostmi, včetně těch na vozíčku. Nabízíme inspirativní prostředí pro rozvoj digitálních dovedností, kreativity a logického myšlení.",
    keywords: "online kurzy Středočeský kraj, programování děti, vzdělávání mládeže online",
    slug: "online-kurzy-programovani-deti-mladez-stredocesky-kraj",
    slug2: "stredocesky-kraj",
    seoDescription:
      "Rozvíjejte své digitální dovednosti s online kurzy programování pro děti a mládež z Jihočeského kraje. Flexibilní vzdělávání pro všechny, bez ohledu na fyzická omezení."
  },
  {
    title: "Jihočeský kraj",
    description: "Online kurzy programování pro mladé z Jihočeského kraje. Vzdělávání bez hranic.",
    longDescription:
      "V Jihočeském kraji nabízíme online kurzy programování pro děti a mládež, které propojují technické dovednosti s tvořivostí. Tyto kurzy jsou snadno přístupné z jakéhokoli místa a jsou vhodné pro širokou škálu účastníků, včetně těch s fyzickými omezeními. Je to ideální způsob, jak se naučit nové dovednosti a prozkoumat svět technologií.",
    keywords: "programování online Jihočeský kraj, kurzy pro děti, vzdělávání mládeže",
    slug: "online-kurzy-programovani-deti-mladez-jihočeský-kraj",
    slug2: "jihočeský-kraj",
    seoDescription:
      "Online kurzy programování pro děti a mládež v Plzeňském kraji. Podporujeme kreativitu a logické myšlení s flexibilním přístupem k vzdělávání."
  },
  {
    title: "Plzeňský kraj",
    description: "Rozvíjejte programovací dovednosti dětí z Plzeňského kraje online. Kurzy pro každého.",
    longDescription:
      "Plzeňský kraj přichází s online kurzům programování, které jsou otevřeny dětem a mládeži. Tyto interaktivní kurzy podporují kreativní myšlení a poskytují nástroje pro rozvoj v oblasti IT. Dostupnost kurzů z jakéhokoli místa činí učení flexibilním a inkluzivním.",
    keywords: "Plzeňský kraj programování online, kurzy pro mládež, dětské vzdělávací kurzy",
    slug: "online-kurzy-programovani-deti-mladez-plzensky-kraj",
    slug2: "plzensky-kraj",
    seoDescription:
      "Objevte online kurzy programování pro děti a mládež v Karlovarském kraji. Flexibilní vzdělávání zaměřené na rozvoj digitálních dovedností a kreativity."
  },
  {
    title: "Karlovarský kraj",
    description: "Karlovarský kraj: Programujte online. Kurzy pro děti a mládež.",
    longDescription: "Karlovy Vary vedou cestu v online vzdělávání v technologiích. Začněte s námi.",
    keywords: "Karlovarský kraj online kurzy, děti programování, technologické vzdělávání",
    slug: "online-kurzy-karlovarsky-kraj",
    slug2: "karlovarsky-kraj",
    seoDescription: "Online kurzy programování pro děti a mládež v Ústeckém kraji. Podporujeme učení odkudkoli a rozvoj technických dovedností pro budoucnost."
  },
  {
    title: "Ústecký kraj",
    description: "Ústecký kraj přináší online programování pro mládež. Vzdělávejte se z libovolného místa.",
    longDescription:
      "V Ústeckém kraji poskytujeme příležitost zapojit se do online kurzů programování určených pro děti a mládež. Tyto kurzy jsou navrženy s ohledem na flexibilitu a přístupnost, umožňující učení z domova pro všechny, včetně osob s omezenými možnostmi pohybu. Nabízíme kvalitní vzdělávací materiály, které podporují rozvoj digitální gramotnosti a kreativního myšlení.",
    keywords: "Ústecký kraj online programování, kurzy pro mladé, přístupné vzdělávání",
    slug: "online-programovani-deti-mladez-ustecky-kraj",
    slug2: "ustecky-kraj",
    seoDescription:
      "Flexibilní online kurzy programování pro děti a mládež v Libereckém kraji. Rozvíjejte své digitální dovednosti a tvořivost z pohodlí domova."
  },
  {
    title: "Liberecký kraj",
    description: "Liberecký kraj nabízí kurzy programování pro mládež online. Učte se, kdekoliv jste.",
    longDescription:
      "Kurzy programování online pro děti a mládež z Libereckého kraje jsou navrženy tak, aby podporovaly zvídavost a inovativní myšlení. Umožňujeme mladým lidem objevovat svět technologií a vývoje softwaru bez ohledu na jejich fyzické umístění nebo schopnosti, s cílem poskytnout všem rovné vzdělávací příležitosti.",
    keywords: "Liberecký kraj programování online, dětské technologické kurzy, vzdělávání z domova",
    slug: "online-kurzy-programovani-liberecky-kraj",
    slug2: "liberecky-kraj",
    seoDescription:
      "Online kurzy programování pro děti a mládež v Královéhradeckém kraji. Podpora kreativity a logického myšlení s flexibilním vzděláváním odkudkoli."
  },
  {
    title: "Královéhradecký kraj",
    description: "Online programovací kurzy v Královéhradeckém kraji pro děti a mládež. Otevřete dveře k technologii.",
    longDescription:
      "V Královéhradeckém kraji poskytujeme online kurzy programování, které jsou přizpůsobené potřebám dětí a mladých lidí. Tyto kurzy nabízí jedinečnou příležitost pro osobní i profesní rozvoj z jakéhokoli místa, podporujíce tvořivost a inovativní řešení problémů prostřednictvím technologie.",
    keywords: "Královéhradecký kraj programování, online vzdělávací kurzy, technologie pro mládež",
    slug: "online-programovani-královehradecky-kraj",
    slug2: "královehradecky-kraj",
    seoDescription: "Rozvíjejte své digitální dovednosti s online kurzy programování pro děti a mládež v Pardubickém kraji. Flexibilní vzdělávání pro všechny."
  },
  {
    title: "Pardubický kraj",
    description: "Pardubický kraj uvádí online kurzy programování pro mládež. Rozvíjejte dovednosti odkudkoliv.",
    longDescription:
      "V Pardubickém kraji nabízíme inovativní online kurzy programování pro děti a mladé lidi. Tyto kurzy jsou navrženy tak, aby byly přístupné z jakéhokoliv místa a poskytovaly vzdělání vhodné pro každého, včetně těch s fyzickými omezeními. Učte se nové dovednosti v oblasti IT a softwarového vývoje, zatímco rozvíjíte kreativitu a řešení problémů.",
    keywords: "Pardubický kraj online programování, dětské vzdělávací kurzy, přístupnost vzdělávání",
    slug: "online-programovani-deti-mladez-pardubicky-kraj",
    slug2: "pardubicky-kraj",
    seoDescription:
      "Online kurzy programování pro děti a mládež na Vysočině. Flexibilní a přístupné vzdělávání zaměřené na rozvoj digitálních dovedností a kreativity."
  },
  {
    title: "Vysočina",
    description: "Vysočina nabízí online programovací kurzy pro děti a mládež. Učení bez hranic, dostupné pro každého.",
    longDescription:
      "Na Vysočině poskytujeme širokou škálu online kurzů programování, které jsou otevřené pro děti a mladé lidi z celého kraje. Naše kurzy podporují digitální gramotnost a otevírají dveře do světa technologií, softwarového vývoje a kreativního myšlení. Bez ohledu na vaše fyzické umístění nebo schopnosti, naše kurzy jsou navrženy tak, aby byly přístupné a flexibilní.",
    keywords: "Vysočina online kurzy, programování pro mládež, vzdělávání z domova",
    slug: "online-kurzy-programovani-vysocina",
    slug2: "vysocina",
    seoDescription: "Online kurzy programování pro děti a mládež v Jihomoravském kraji. Podpora technických dovedností a kreativity s flexibilním vzděláváním."
  },
  {
    title: "Jihomoravský kraj",
    description: "Jihomoravský kraj představuje online kurzy programování pro mladé. Startujte svou cestu v IT odkudkoliv.",
    longDescription:
      "Jihomoravský kraj je domovem špičkových online kurzů programování pro děti a mládež. Tyto kurzy jsou navrženy pro všechny úrovně dovedností a nabízejí možnost naučit se základy kódování, vývoje softwaru a digitálního designu. Bez ohledu na vaši lokaci nebo osobní výzvy, naše kurzy jsou přístupné a podporují každého v jeho učební cestě.",
    keywords: "Jihomoravský kraj programování online, dětské IT kurzy, dostupné vzdělávání",
    slug: "online-programovani-deti-mladez-jihomoravsky-kraj",
    slug2: "jihomoravsky-kraj",
    seoDescription:
      "Objevte online kurzy programování pro děti a mládež v Olomouckém kraji. Flexibilní a přístupné vzdělávání pro všechny, bez ohledu na fyzická omezení."
  },
  {
    title: "Olomoucký kraj",
    description: "Olomoucký kraj a jeho online kurzy programování pro děti. Učte se technologie z pohodlí domova.",
    longDescription:
      "V Olomouckém kraji poskytujeme širokou škálu online kurzů programování, přístupných pro děti a mládež z každého koutu kraje. Naše kurzy nabízejí ucelený přehled o základech programování, softwarovém vývoji a digitální tvorbě, s cílem podpořit kreativní myšlení a technické dovednosti. Kurzy jsou vhodné pro všechny, bez ohledu na fyzické schopnosti nebo předchozí zkušenosti.",
    keywords: "Olomoucký kraj online programování, kurzy pro děti, digitální vzdělávání",
    slug: "online-programovani-deti-mladez-olomoucky-kraj",
    slug2: "olomoucky-kraj",
    seoDescription:
      "Rozvíjejte své digitální dovednosti s online kurzy programování pro děti a mládež ve Zlínském kraji. Podpora kreativity a logického myšlení."
  },
  {
    title: "Moravskoslezský kraj",
    description: "Začněte programovat online v Moravskoslezském kraji. Kurzy pro mladé nadšence technologií.",
    longDescription:
      "Moravskoslezský kraj přináší možnost učit se programování online pro děti a mládež. Nabízíme kurzy, které jsou navrženy k rozvoji dovedností v oblasti IT, programování a kreativního myšlení. Učte se v bezpečném a podporujícím prostředí z domova, s kurzy přizpůsobenými pro různé věkové skupiny a úrovně znalostí.",
    keywords: "Moravskoslezský kraj programování online, technologie pro děti, vzdělávání mládeže",
    slug: "online-programovani-deti-mladez-moravskoslezsky-kraj",
    slug2: "moravskoslezsky-kraj",
    seoDescription:
      "Online kurzy programování pro děti a mládež v Moravskoslezském kraji. Flexibilní vzdělávání zaměřené na rozvoj digitálních dovedností a kreativity."
  },
  {
    title: "Zlínský kraj",
    description: "Online programovací kurzy pro děti v Zlínském kraji. Rozvíjejte své IT dovednosti z jakéhokoli místa.",
    longDescription:
      "Zlínský kraj nabízí široké spektrum online kurzů programování pro děti a mládež, které jsou navrženy tak, aby podporovaly zájem o technologie a informatiku. Kurzy pokrývají širokou škálu témat od základů programování po pokročilé koncepty vývoje softwaru, vše v přátelském a podporujícím online prostředí.",
    keywords: "Zlínský kraj online programování, dětské IT kurzy, rozvoj dovedností",
    slug: "online-programovani-deti-mladez-zlinsky-kraj",
    slug2: "zlinsky-kraj",
    seoDescription: "Online kurzy programování pro děti a mládež v Libereckém kraji. Rozvíjejte své digitální dovednosti a tvořivost z pohodlí domova."
  }
]
